import React, { Component } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  DialogActions,
  Dialog,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import {
  participantsUpdateRoute,
  tournamentSignUpsShowRoute,
  participantsDeleteRoute,
  baseDomain,
} from "../../../network/api-routes";
import performRequest from "../../../network/perform-request";
import withUserContext from "../../hoc/withUserContext";
import { withSnackbar } from "notistack";
import moment from "moment";
import {
  CalendarTodayOutlined,
  MonetizationOnOutlined,
  PeopleOutline,
  PersonOutline,
  ReceiptOutlined,
  SportsOutlined,
} from "@mui/icons-material";
import { green } from "@mui/material/colors";
import AlertDialog from "../../common/AlertDialog";
import EditParticipantModal from "../Tournament/EditParticipantModal";
import ProfilesModal from "../Tournament/ProfilesModal.js";

const styles = (theme) => ({
  modalContainer: {
    width: "100%",
    padding: 30,
    margin: "auto",
    borderRadius: 5,
    minHeight: 400,
  },
  largeAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  searchUserContainer: {
    marginTop: theme.spacing(4),
  },
  iconContainer: {
    backgroundColor: theme.palette.background.default,
    color: "white",
    padding: 10,
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: 54,
    height: 54,
    justifyContent: "center",
  },
  icon: {
    fontSize: 30,
  },
  label: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontSize: 10,
  },
});

const ItemWithIcon = (props) => {
  const { label, value, valueColor, icon, footer, classes } = props;

  return (
    <Grid xs={12} item container alignItems="center">
      <Grid item xs={2}>
        <div className={classes.iconContainer}>{icon}</div>
      </Grid>
      <Grid item xs={10}>
        <label className={classes.label}>{label}</label>
        <Typography color={valueColor || "textPrimary"} variant="h3">
          {value}
        </Typography>
        {footer}
      </Grid>
    </Grid>
  );
};

class EditSignUpModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      signUp: null,
      openEditParticipantModal: false,
      openProfilesModal: false,
    };
    this.togglePaid = this.togglePaid.bind(this);
  }

  componentDidMount() {
    this.fetch(this.props.userContext.user);
  }

  fetch(user) {
    const { signUpId } = this.props;
    performRequest(
      "POST",
      tournamentSignUpsShowRoute,
      { tournament_sign_up_id: signUpId },
      true,
      user
    )
      .then((response) => {
        this.setState({
          signUp: response.data.data,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  togglePaid() {
    const signUp = this.state.signUp;
    const paid = signUp.status !== "waiting-payment";
    const status = paid ? "waiting-payment" : "completed";
    const participant = signUp.participant.data;
    const { user } = this.props.userContext;

    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        participantsUpdateRoute,
        {
          participant_id: participant.id,
          status,
        },
        true,
        user
      )
        .then((response) => {
          this.props.enqueueSnackbar(
            paid ? "Marcado como pendiente" : "Marcado como pagado",
            {
              variant: "success",
            }
          );
          this.props.onSave();
        })
        .catch((error) => {
          this.props.enqueueSnackbar(
            error?.response?.data?.message || "Ha ocurrido un error",
            {
              variant: "error",
            }
          );
          console.log(error);
        });
    });
  }

  delete() {
    const { user } = this.props.userContext;
    performRequest(
      "POST",
      participantsDeleteRoute,
      {
        participant_id: this.state.signUp.participant_id,
      },
      true,
      user
    )
      .then((response) => {
        this.props.enqueueSnackbar("Participante borrado", {
          variant: "success",
        });
        this.props.onSave();
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.response.data.message, {
          variant: "error",
        });
        console.log(error);
      });
  }

  openPaymentsLink(id) {
    const url = `${baseDomain}/admin/online-payments/${id}`;
    console.log("opening url", url);
    window.open(url, "_blank");
  }

  renderEditParticipantDialog() {
    return (
      <Dialog
        open={this.state.openEditParticipantModal}
        onBackdropClick={() => {
          this.setState({
            openEditParticipantModal: false,
          });
          this.fetch();
        }}
        disableAutoFocus
        fullWidth={true}
        maxWidth="md"
      >
        <EditParticipantModal
          participantId={this.state.signUp.participant_id}
          hideTournamentSignUp={true}
          onSave={(newParticipant) => {
            this.props.onParticipantUpdated(newParticipant);
            this.setState({ openEditParticipantModal: false });
          }}
        ></EditParticipantModal>
      </Dialog>
    );
  }

  renderProfilesModal() {
    const signUp = this.state.signUp;
    const participant = signUp.participant.data;
    return (
      <Dialog
        open={this.state.openProfilesModal}
        onBackdropClick={() => this.setState({ openProfilesModal: false })}
        disableAutoFocus
        fullWidth={true}
        maxWidth="md"
      >
        <ProfilesModal
          participant={participant}
          editParticipant={() => {
            this.setState({
              openProfilesModal: false,
              openEditParticipantModal: true,
            });
          }}
        />
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    if (!this.state.ready) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.modalContainer}
        >
          <CircularProgress />
        </Grid>
      );
    }
    const signUp = this.state.signUp;
    const paid = signUp.status !== "waiting-payment";

    const tournament = signUp.tournament.data;
    const participant = signUp.participant.data;
    const user = signUp.user.data;
    const paymentType = signUp.payment_id ? "Pago Online" : "Efectivo/Otro";
    const paymentID = signUp.payment_id;
    console.log("signup is ", signUp);

    return (
      <>
        <Grid className={classes.modalContainer}>
          <Typography variant="h1" style={{ marginBottom: 20 }}>
            Detalles de inscripción
          </Typography>
          <Grid container style={{ height: "100%" }}>
            <Grid
              item
              container
              xs={12}
              lg={6}
              spacing={3}
              justifyContent="center"
            >
              <ItemWithIcon
                classes={classes}
                label="Torneo"
                value={tournament.name}
                icon={
                  <SportsOutlined className={classes.icon}></SportsOutlined>
                }
              />
              <ItemWithIcon
                classes={classes}
                label="Participante"
                value={participant.display_name}
                icon={<PeopleOutline className={classes.icon}></PeopleOutline>}
                footer={
                  <Button
                    onClick={() => this.setState({ openProfilesModal: true })}
                    style={{ padding: 0, minWidth: 0, marginRight: 10 }}
                  >
                    <Typography style={{ color: green[300], fontSize: 10 }}>
                      Ver perfiles
                    </Typography>
                  </Button>
                }
              />
              <ItemWithIcon
                classes={classes}
                label="Fecha"
                value={` Creada el ${moment(signUp.created_at).format(
                  "ddd DD/MM HH:mm"
                )}`}
                icon={
                  <CalendarTodayOutlined
                    className={classes.icon}
                  ></CalendarTodayOutlined>
                }
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              lg={6}
              spacing={3}
              justifyContent="center"
            >
              {!signUp.is_admin && (
                <ItemWithIcon
                  classes={classes}
                  label="Creador"
                  value={user.full_name}
                  icon={
                    <PersonOutline className={classes.icon}></PersonOutline>
                  }
                  footer={
                    <>
                      <Button
                        style={{ padding: 0, minWidth: 0, marginRight: 10 }}
                      >
                        <Typography style={{ color: green[300], fontSize: 10 }}>
                          <a href={`tel:${user.cellphone_number}`}>Llamar</a>
                        </Typography>
                      </Button>
                      <Button style={{ padding: 0, minWidth: 0 }}>
                        {user.cellphone_number && (
                          <Typography
                            style={{ color: green[300], fontSize: 10 }}
                          >
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`https://wa.me/${user.cellphone_number.replace(
                                "+",
                                ""
                              )}`}
                            >
                              WhatsApp
                            </a>
                          </Typography>
                        )}
                      </Button>
                    </>
                  }
                ></ItemWithIcon>
              )}
              <ItemWithIcon
                classes={classes}
                label="Tipo de pago"
                value={paymentType}
                icon={
                  <MonetizationOnOutlined
                    className={classes.icon}
                  ></MonetizationOnOutlined>
                }
                footer={
                  paymentID && (
                    <Button
                      onClick={() => {
                        this.openPaymentsLink(paymentID);
                      }}
                    >
                      Ver boleta
                    </Button>
                  )
                }
              ></ItemWithIcon>

              <ItemWithIcon
                classes={classes}
                label={"Estado de pago"}
                value={paid ? "Pagado" : "Esperando pago"}
                icon={
                  <ReceiptOutlined className={classes.icon}></ReceiptOutlined>
                }
                valueColor={paid ? "primary" : "secondary"}
              ></ItemWithIcon>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions>
          {!signUp.payment_id && (
            <Button onClick={this.togglePaid}>
              {paid ? "Marcar como pago pendiente" : "Marcar como pagado"}
            </Button>
          )}

          <Button
            onClick={() => {
              this.setState({ openDeleteAlert: true });
            }}
          >
            Borrar inscripción
          </Button>
        </DialogActions>

        <AlertDialog
          hideButton
          isOpen={this.state.openDeleteAlert}
          onConfirm={() =>
            this.setState({ openDeleteAlert: false }, this.delete)
          }
          onCancel={() => this.setState({ openDeleteAlert: false })}
          alertBody="¿Estás seguro que quieres borrar a este participante de tu torneo? Aseguráte de haberlo conversado previamente"
          confirmButtonTitle="Si, estoy seguro"
          cancelButtonTitle="Volver atrás"
        />
        {this.renderEditParticipantDialog()}
        {this.renderProfilesModal()}
      </>
    );
  }
}

export default withSnackbar(
  withUserContext(withStyles(styles)(EditSignUpModal))
);
